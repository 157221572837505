import React, { useEffect } from 'react'
import Layout from './Layout'
import Welcome from '../components/Welcome'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMe } from '../features/AuthSlice'
// import HeatmapChart from './ChartHeatMap'

const Dasboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate('/');
    }
  }, [isError, navigate]);
  
  return (
    <Layout >
        <Welcome />
        {/* <HeatmapChart /> */}
    </Layout>
  )
}

export default Dasboard